import React from "react"
import styled from "styled-components"
import device from "../device"
import Img from "gatsby-image/withIEPolyfill"

const Wrapper = styled.section`
  .bar {
    padding: 5rem 0;
    background: var(--darkgrey);
    ${device.small`padding: 2rem 0;`}
  }
`

const Grid = styled.div`
  margin: 5rem auto;
  width: 85%;
  max-width: 1500px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 400px;
  grid-gap: 1rem;
  ${device.small`width: var(--spread);`}
  ${device.medium`grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));`}

  .grid-item {
    position: relative;
    text-align: center;

    &:hover {
      .content-wrapper {
        height: 40%;
        background: rgba(166, 168, 109, 0.85);
        ${device.small`height: 30%;`}
      }
    }
  }

  .content-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    color: var(--white);
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    ${device.small`height: 30%;`}
    .content {
      width: 70%;
      ${device.small`width: 80%;`}
      .excerpt {
        font-size: 0.9rem;
        margin-top: 1rem;
        p {
          line-height: 1.4;
        }
      }
    }
  }

  .image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    .image {
      width: 100%;
      height: 100%;
    }
  }
`

const Tiles = ({ tiles }) => {
  return (
    <Wrapper>
      <Grid>
        {tiles.map(({ node }) => (
          <div className="grid-item" key={node.id}>
            <div className="content-wrapper">
              <div className="content">
                <h3 className="title">{node.title}</h3>
                <div
                  className="excerpt"
                  dangerouslySetInnerHTML={{ __html: node.excerpt }}
                />
              </div>
            </div>
            <div className="image-wrapper">
              {node.featured_media.localFile && (
                <Img
                  className="image"
                  fluid={node.featured_media.localFile.childImageSharp.fluid}
                  alt={node.featured_media.alt_text}
                />
              )}
            </div>
          </div>
        ))}
      </Grid>
      <div className="bar"></div>
    </Wrapper>
  )
}

export default Tiles

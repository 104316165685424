import React from "react"
import { graphql } from "gatsby"

import GreenHeading from "../components/heading"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Hero from "../components/hero/hero"
import Reasons from "../components/reasons/reasons"
import Reviews from "../components/reviews/reviews"
import Download from "../components/download"
import Locations from "../components/locations/locations"
import Content from "../components/techpage/content"
import Tiles from "../components/techpage/tiles"
import TravelDestinations from "../components/TravelDestinations"
import { useTravelDestinationsQuery } from "../components/hooks/use-travel-destinations-query"

const TechPage = ({ data }) => {
  const travelDestinations = useTravelDestinationsQuery()
  const firstTitle = data.techpage.title.split(" ")[0]
  const secondTitle = data.techpage.title.replace(firstTitle, "")

  const includedTiles = data.techpage.acf.tech_tiles.map(t => t.wordpress_id)
  const filteredTiles = data.techtiles.edges.filter(({ node }) =>
    includedTiles.includes(node.wordpress_id)
  )

  return (
    <Layout>
      <Helmet>
        <title>{`${data.site.siteMetadata.title} | ${data.wpgraphql.page.seo.title}`}</title>
        <meta
          name="description"
          content={data.wpgraphql.page.seo.opengraphDescription}
        />
        <meta
          property="og:url"
          content={data.wpgraphql.page.seo.opengraphUrl}
        />
        <meta
          property="og:site_name"
          content={data.wpgraphql.page.seo.opengraphSiteName}
        />
        <meta property="og:title" content={data.wpgraphql.page.seo.title} />
        <meta
          property="og:description"
          content={data.wpgraphql.page.seo.opengraphDescription}
        />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Twitter */}
        <meta
          name="twitter:title"
          content={data.wpgraphql.page.seo.twitterTitle}
        />
        <meta
          name="twitter:description"
          content={data.wpgraphql.page.seo.twitterDescription}
        />

        <meta name="geo.region" content="US-PA" />
        <meta name="geo.placename" content="King of Prussia" />
        <meta name="geo.position" content="40.091710;-75.346160" />
        <meta name="ICBM" content="40.091710, -75.346160" />
      </Helmet>
      <Hero
        bannerData={{
          desktop: data.techpage.acf.banner_desktop,
          mobile: data.techpage.acf.banner_mobile,
        }}
      >
        <h1 className="heading fleet">
          <GreenHeading size="6rem" text={firstTitle} animate={false} />
          <br />
          <span className="last">{secondTitle}</span>
        </h1>
      </Hero>

      <Content data={data.techpage} />
      <Tiles tiles={filteredTiles} />
      <TravelDestinations data={travelDestinations} />
      <Reasons />
      <Reviews />
      <Download text={data.home.acf.download_text} />
      <Locations text={data.home.acf.locations_text} />
    </Layout>
  )
}

export default TechPage

export const query = graphql`
  {
    techpage: wordpressPage(
      template: { eq: "templates/template-tech-page.php" }
    ) {
      title
      acf {
        banner_desktop {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        banner_mobile {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        tech_heading
        tech_content
        tech_tiles {
          wordpress_id
        }
      }
    }

    techtiles: allWordpressWpTechnologyTiles {
      edges {
        node {
          wordpress_id
          title
          excerpt
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    home: wordpressPage(title: { eq: "Home" }) {
      acf {
        client_reviews_text
        download_text
        welcome_text
        locations_text
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
    wpgraphql {
      page(id: "378", idType: DATABASE_ID) {
        nexvelschemapagesposts {
          videos {
            title
            url
            description
            thumbnailImage {
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
            uploadDate
          }
          questionsAndAnswers {
            question
            answer
          }
          maps {
            mapUrl
          }
          digitaldocuments {
            title
          }
          images {
            image {
              date
              description
              title
              sourceUrl
              imageFile {
                childImageSharp {
                  fixed {
                    src
                  }
                }
              }
            }
          }
          areaServedLandingPageSchema
          serviceSchema {
            serviceNameSchema
            linkToRelevantServicePageSchema
          }
          keywordsSchema {
            keywordSchema
          }
          localAwardsSchema {
            localAwardSchema
          }
          latitudeSchema
          longitudeSchema
          relevantExternalPagesSchema {
            relevantExternalPageSchema
          }
        }
        seo {
          title
          opengraphDescription
          opengraphUrl
          opengraphSiteName
          twitterTitle
          twitterDescription
        }
      }
    }
    wordpressAcfOptions {
      options {
        cities {
          city
        }
        services {
          service
          link_to_relevant_page_on_your_website
        }
      }
    }
  }
`
